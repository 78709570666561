
.Main {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-evenly;
    background-color: rgb(32, 32, 32);
    font-size: clamp(0.5rem, 3.5vw ,1rem);
    padding: 70px 0;
    gap: 8vh;
    text-align: center;
    box-shadow: 0 0 20px black;
    position: absolute;
    transform: translate(0, 1px);

    .specifics {
        display: flex;
        flex-direction: column;
        gap: 2vh;
        padding: 25px 0 0 5vw;
        border-radius: 15px;
    }

    .price {
        display: flex;
        flex-direction: column;
        gap: 1vh;
        padding: 25px 5vw;
        border-left: solid 1px grey;
        border-right: solid 1px grey;
    }

    .delivery {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1vh;
        padding: 25px 5vw 0 0;
        border-radius: 15px;
    }

    h2 {
        font-weight: 300;
        max-width: 600px; 
    }

    h1 {
        color: wheat;
    }

    strong {
        font-weight: 600;
    }
}

@media (max-width: 650px) {
    .Main {
        display: flex;
        height: fit-content;
        justify-content: space-evenly;
        flex-direction: column;
        align-items: center;
        font-size: clamp(0.5rem, 4.5vw ,1rem);
        padding: 40px 0;
        position: unset;
        box-shadow: none;

        .price {
            padding: 6vh 0;
            border-left: none;
            border-right: none;
            border-top: solid 1px grey;
            border-bottom: solid 1px grey;
        }

        h2 {
            max-width: 450px;
            padding: 0 5vw;
        }
    }
}