.main {
    background-image: url("../Images/Kubls.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 100vh;

    .cover {
        width: inherit;
        height: inherit;
        background-color: rgba(black, 0.5);
    }

    .main--content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        text-align: center;
        

        .logo {
            width: 50vw;
        }

        .main--text {
            font-size: 2rem;
            margin: 40px 10% 0 10%;

            h2 {
                text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
                color: wheat;
            }

            p {
                color: wheat;
                margin: 20px 0 40px;
                text-shadow: 0 0 40px rgba(0, 0, 0, 0.5);
            }

            .reserve--btn {
                color: rgba(237, 237, 237, 0.9);
                border: none;
                padding: 1.4% 14%;
                background-color: rgba(255, 255, 255, 0);
                border-radius: 2px;
                border: 1px solid rgb(169, 169, 169);
                cursor: pointer;
                font-size: 1.5rem;
                transition: ease-in-out 0.1s;
            }

            .reserve--btn:hover {
                padding: 1.4% 14.5%;
                border: 1px solid white;
                font-weight: 500;
                background-color: rgba(255, 255, 255, 0.1);
            }

            .reserve--btn:active {
                padding: 1.4% 14.5%;
                border: 1px solid white;
                font-weight: 500;
                background-color: rgba(255, 255, 255, 0.4);
            }
        }
    }
}

@media (max-width: 650px) {
    .main {
        .main--content {
            .logo {
                width: 85vw;
            }
    
            .main--text {
                font-size: 1.7rem;
            }
        }
    }
}
