.footer {
    display: flex;
    align-items: end;
    justify-content: center;
    height: fit-content;
    width: 100%;

    .contacts {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin: 30px;
        padding-top: 5vh;
        border-top: solid 1px rgba(207, 207, 207, 0.4);

        .logo {
            max-width: 30%;
            padding: 10px 0 20px;
            img {
                width: 100%;
            }
        }

        .info {
            padding: 35px 0;
            display: flex;
            justify-content: space-evenly;
            width: 90%;
            gap: 25px;


            h4 {
                font-size: clamp(1rem, 1.5vw ,1.3rem);
                font-weight: 400;
                color: rgba(255, 255, 255, 0.8);
            }

            .location {

                a {
                    font-size: clamp(1rem, 1.5vw ,1.3rem);
                    font-weight: 400;
                    color: rgba(255, 255, 255, 0.8);
                    padding: 0 0 6px;
                    border-bottom: 2px solid rgba(223, 223, 223, 0.5);
                    text-decoration: none;
                    transition: ease-in-out 0.2s;
                    cursor: pointer;
                }
        
                a:hover {
                    padding: 0 15px 7px;
                    border-bottom: 2px solid rgba(161, 161, 161, 1);
                    color: rgb(255, 221, 158);
                }
            }

            .loc {
                margin-bottom: 20px;
            }

            h2 {
                text-align: left;
                padding: 0 0 20px 20px;
                max-width: 400px;
                border-bottom: solid 1px rgba(255, 255, 255, 0.3);
                color: wheat;
            }

            .pair{
                display: flex;
                align-items: center;
                gap: 20px;
                padding: 20px 0 20px 20px;
                border-bottom: solid 1px rgba(255, 255, 255, 0.3);
                max-width: 400px;

                img {
                    width: 2vw;
                }
            }
        }
    }
}

@media (max-width: 1300px) {
    .footer {
        .contacts {
            flex-direction: column;
            gap: 3vh;
            margin: 30px;
    
            .logo {
                max-width: 30%;
                margin-left: 0;
        
                img {
                    width: 100%;
                }
            }
    
            .info {
                display: flex;
                justify-content: space-evenly;
                width: 90%;
                margin-right: 0;
                gap: 20px;
                
                .about {
                    max-width: 35%;
                    display: flex;
                    flex-direction: column;
                    padding: 0;
    
                    h4 {
                        padding-top: 20px;
                        font-size: clamp(1rem, 1.5vw ,1.2rem);
                        color: rgba(255, 255, 255, 0.8);
                    }
                }
                
                .pair{
                    img {
                        width: 30px;
                    }
                }
            }
        }
    }
}

@media (max-width: 955px) {
    .footer {
        .contacts {
            margin: 0 5%;
            padding: 40px 0;
    
            .logo {
                max-width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
        
                img {
                    height: auto;
                    width: 100%;
                }
            }
    
            .info {
                display: flex;
                align-items: center;
                flex-direction: column;
                gap: 20px;
                padding: 0;

                h2 {
                    padding: 0 0 20px 0;
                    text-align: center;
                }

                .location {
                    margin-top: 30px;
                }

                .about {
                    margin-top: 30px;
                    max-width: 80%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 0; 

                    h2 {
                        max-width: 80%;
                    }
                    
                    h4 {
                        max-width: 80%;
                        padding-top: 20px;
                        font-size: clamp(1.1rem, 1.5vw ,1.3rem);
                        color: rgba(255, 255, 255, 0.8);
                    }
                }
            }
        }
    }
}