
.main--area {
    padding: 20vh 0 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .main--container {
        background-color: rgb(32, 32, 32);
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.733);
        border-radius: 15px;
        display: flex;
        align-items: center;
        flex-direction: column;
        max-width: 90%;
        padding: 8vh 10vw;

        .about {
            max-width: 80%;
            display: flex;
            flex-direction: column;
            padding: 8vh 0 0 0 ;

            h2 {
                max-width: 100%;
                text-align: center;
                color: wheat;
                border-bottom: solid 2px rgb(124, 124, 124);
                padding-bottom: 15px;
            }

            h4 {
                padding-top: 20px;
                font-size: clamp(1rem, 1.5vw ,1.2rem);
                color: rgba(255, 255, 255, 0.8);
                text-align: center;
            }
        }

        .main--text {
            h1 {
                font-size: 2.2rem;
                color: wheat;
                text-align: center;
                transform: translate(0, -30px);
            }    
        }

        .container {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5%;
    
            .Card {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: column;
                height: clamp(35vh, 14.5vw, 50vh);
                width: 15vw;
                background-color: rgba(0, 0, 0, 0.2);
                border-radius: 10px;
                box-shadow: 0 0 35px rgba(0, 0, 0, 0.7);
                margin-top: 4vh;
                
                .dot {
                    padding: 8% 0 0;
                }
    
                h2 {
                    margin: 0 20px;
                    font-size: clamp(1rem, 1.5vw ,1.6rem);
                    text-align: center;
                    color: rgb(255, 222, 171);
                }
    
                .icon {
                    max-width: 40%;
                    margin-bottom: 15%;
                }
            }
        }
    }
}


@media (max-width: 650px) {
    .main--area {
        padding: 10vh 0;
        display: flex;
        justify-content: center;
        width: 100%;
    
        .container {
            width: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 5vh 6%;

            .Card {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: column;
                min-height: 38vh;
                min-width: 45vw;
                background-color: rgba(0, 0, 0, 0.2);
                border-radius: 10px;
                box-shadow: 0 0 25px rgba(0, 0, 0, 0.7);
                scroll-snap-align: center;
                margin-top: 15%;
    
                h2 {
                    margin: 0 20px;
                    font-size: clamp(1rem, 3.5vw, 2rem);
                    text-align: center;
                    color: rgb(255, 255, 255);
                }
    
                .icon {
                    width: 40%;
                    margin-bottom: 15%;
                }
            }
        }
    }
}