
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


.line {
  margin: auto;
  width: 90%;
  background-color: grey;
  height: 2px; 
}

.lower {
  transform: translate(0, -10vh);
}

.upper {
  transform: translate(0, -10vh);
}

.slider {
    width: 100%;
    margin: 0 0 10vh 0;
  
    .slide {
        padding: 20% 0;
        transform: scale(0.75);
        transition: transform 200ms;
        opacity: 0.5;

      img {
        width: 100%;
        margin: 0 auto;
        border-radius: 5%;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      }
  
      &.activeSlide {
        transform: scale(1.2);
        opacity: 1;

        img {
          width: 100%;
          border-radius: 5%;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        }
      }
    }
  
    .arrow {
      background-color: #000000;
      padding: 1.5% 3%;
      position: absolute;
      cursor: pointer;
      z-index: 10;
      border-radius: 5%;
  
      svg {
        transition: color 300ms;
      }
  
      &:hover {
        svg {
          color: #000000;
        }
      }
    }
  
    .next {
      right: 0;
      top: 50%;
      margin-right: 1%;
    }
  
    .prev {
      left: 0%;
      top: 50%;
      margin-left: 1%;
    }
  }

@media (max-width: 650px) {
  .slider {
    width: 100%;
    height: 40vh;
    margin: 10vh 0 0 0;

    .slide {
      padding: none;
      transform: none;
      transition: transform 200ms;
      opacity: 0;

      img {
        width: 100%;
        margin: 0 auto;
        border-radius: 0;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      }
  
      &.activeSlide {
        transform: scale(2);
        opacity: 1;
        width: 90%;
        height: 30vh;

        img {
          box-shadow:none;
          border-radius: 0;
        }
      }
    }
  
    .arrow {
      background-color: #000000;
      padding: 1.5% 4%;
      position: absolute;
      cursor: pointer;
      z-index: 10;
      border-radius: 5%;
  
      svg {
        transition: color 300ms;
      }
  
      &:hover {
        svg {
          color: #000000;
        }
      }
    }
  
    .next {
      right: 0;
      top: 42%;
      margin-right: 1%;
    }
  
    .prev {
      left: 0%;
      top: 42%;
      margin-left: 1%;
    }
  }
}
  