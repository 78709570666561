
.form--div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6vh 0;
    margin-top: clamp(70vh, 10vw, 40vh);
}

@media (max-width: 650px) {
    .form--div {
        margin-top: 0;
    }
}