
.navbar {
    transition: background-color 0.1s ease-in-out;
    background-color: transparent;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    width: 100%;

    .animation--div {
      display: none;

      .animation {
        display: none;
      }
    } 

        .nav--items {
            display: flex;
            gap: 40px;

            a {
                text-decoration: none;
                font-size: 1.4rem;
                padding-bottom: 4px;
                color: rgba(237, 237, 237, 0.9);
                border-bottom: 2px solid rgba(161, 161, 161, 0.5);
                transition: ease-in-out 0.2s;
                cursor: pointer;
            }

            a:hover {
                padding: 0 15px 4px;
                color: rgb(255, 255, 255);
                border-bottom: 2px solid rgba(161, 161, 161, 1);
            }
        }
  }
  
  .navbar.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #000000; 
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.5);
    padding: 20px 0;
    cursor: pointer;
    z-index: 10000;
    border-radius: 0 0 25px 25px;
  }

  @media (max-width: 650px) {
    .navbar {
      display: flex;
      flex-direction: column;
      width: 100vw;
      min-height: 12vh;
      padding: 10px 0;
      position: static;
      background-color: #000000;
      
      .burger-menu {
        height: 100%;
        width: 4em;
        display: flex;
        gap: 10px;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        margin-top: 0.5em;
      }
      
      .burger-bar {
          width: 3em;
          height: 0.3em;
          background-color: rgb(255, 255, 255);
          border-radius: 0.5em;
      }

      .burger-bar.clicked:nth-child(1){
          transform: rotate(45deg) translate(0.4em, 0.95em);
          transition: ease-out 0.5s;
          margin-left: 13px;
      }
      
      .burger-bar.clicked:nth-child(2){
          transform: scale(0.1);
          transition: ease-out 0.5s;
      }
      
      .burger-bar.clicked:nth-child(3){
          transform: rotate(135deg) translate(-0.4em, 0.95em);
          transition: ease-out 0.5s;
          margin-left: 13px;
      }

      .burger-bar.unclicked {
          transform: rotate(0) translate(0);
          transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.8s;
      }
      
      .show {
        display: none;
      }

      .nav--items {
        flex-direction: column;
        gap: 20px;
        text-align: center;
        margin-top: 35px;
        z-index: 1;
        animation: slideDown 0.5s forwards;

        a {
          text-decoration: none;
          font-size: 1.4rem;
          padding-bottom: 0;
          color: rgba(237, 237, 237, 0.9);
          border-bottom: none;
          transition: none;
          cursor: pointer;
        } 

        a:hover {
            padding: 0;
            color: rgb(121, 121, 121);
            border-bottom: none;
        }
      }

      @keyframes slideDown {
        0% {
          transform: translateY(-100%);
        }
        100% {
          transform: translateY(0);
        }
      }
    }
  }
  
  